<template>
  <a-config-provider :locale="locale">
    <template v-if="!loading">
      <navroot :class="{ 'phone-mode': phoneMode }" v-if="!hideNav"></navroot>
      <div id="content" :class="{ 'phone-mode': phoneMode, 'hideNav': hideNav }">
        <router-view />
      </div>
      <phoneBar v-if="phoneMode & !hideNav"></phoneBar>
    </template>
    <div class="index-loading" v-else></div>
  </a-config-provider>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, LESSONPLAY_TOKEN } from "./config/url";
import { STORE_ID, VIP_PLATFORM } from '@/config/constant'
import Vibrant from 'node-vibrant';

//汉化
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

import headPath from "@/filter/headPath";
import navroot from "@/component/public/nav/nav.vue";
import phoneBar from "@/component/public/phone-bar/phone-bar.vue";
import { notification } from "ant-design-vue";

export default {
  name: "App",
  data() {
    return {
      urlToken: "",
      token: "",
      user: null,

      storeBooks: [],

      loading: true,
      locale: zhCN,
      phoneMode: false,
      hideNav: false,
    };
  },
  computed: {},
  components: {
    navroot,
    phoneBar,
  },
  async mounted() {
    if (location.href.indexOf('reader') > -1 || location.href.indexOf('bookReader') > -1 || location.href.indexOf('login') > -1 || location.href.indexOf('payfinishCommon') > -1) {
      this.hideNav = true;
    }

    this.urlToken = this.getURLQuery("token");

    this.$store.commit(
      "isElectron",
      !!(window && window.process && window.process.type)
    );
    this.judegeElectron();
    this.judgePhone();

    window.addEventListener("resize", this.judgePhone);
    document.addEventListener("UniAppJSBridgeReady", () => {
      uni.getEnv((res) => {
        if (res.plus) {
          window.getUserFormApp = async (res) => {
            const ret = await this.$api.passport.auth(JSON.parse(res));
            if (ret.status === 1) {
              this.userGuid = ret.userGuid;
              this.token = ret.token;
              window.localStorage.setItem(LESSONPLAY_TOKEN, this.token);
              this.deleteURLToken();
              this.loadFunction();
            }
          };
          uni.postMessage({
            data: {
              action: "getUser",
            },
          });
        }
      });
    })
  },
  methods: {
    //判断手机端
    judgePhone() {
      const w_width = window.innerWidth;
      const phoneMode = w_width < 700;

      if (this.phoneMode == phoneMode) return;
      this.phoneMode = phoneMode;
      this.$store.commit("phoneMode", this.phoneMode);
    },
    //检查是否在electron中
    async judegeElectron() {
      if (this.$store.state.isElectron) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send("config:read");
        ipcRenderer.once("config:read", async (event, data) => {
          const electronConfig = data;
          this.$store.commit("electronConfig", data);
          if (electronConfig.online) {
            // 有网
            if (!electronConfig.token) {
              this.loadFunction();
            } else {
              const ret = await this.$api.passport.auth({
                username: electronConfig.username,
                password: electronConfig.password,
              });
              if (ret.status === 1) {
                this.userGuid = ret.userGuid;
                this.token = ret.token;
                window.localStorage.setItem(LESSONPLAY_TOKEN, this.token);
                this.deleteURLToken();
                this.loadFunction();
              }
              return;
            }
          }
          if (!electronConfig.online) {
            this.loading = false;
            return;
          }
        });
        ipcRenderer.send("notification:read");
        ipcRenderer.on("notification:read", (event, data) => {
          for (let key in data) {
            if (!data[key].played) {
              notification.open({
                message: data[key].title,
                description: data[key].body,
              });
              data[key].played = true;
              ipcRenderer.send("notification:update", {
                key,
                data: data[key],
              });
            }
          }
        });
      } else {
        this.judgeToken();
      }
    },
    async judgeToken() {
      const urlToken = this.getURLQuery("token");
      const token = urlToken || window.localStorage.getItem('LESSONPLAY_TOKEN');

      if (!token) {
        const herfurl = location.href.split("?")[0];
        location.href = `${PASSPORT_CLIENT_SERVER}/auth/checkTouristLogin?redirectURI=${herfurl}`;
      }
      else {
        const ret = await this.$api.passport.verifyToken(token);
        if (ret.status === 1) {
          this.userGuid = ret.userGuid;
          this.token = ret.token;
          window.localStorage.setItem(`LESSONPLAY_TOKEN`, ret.token);
        } else {
          window.localStorage.removeItem('LESSONPLAY_TOKEN');
        }
      }

      this.deleteURLToken();
      this.loadFunction();
    },
    //删除URL中的token
    deleteURLToken() {
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('token')
      const newURL = searchParams.toString() ? `${location.pathname}?${searchParams.toString()} ` : location.pathname
      history.pushState({}, '', newURL)
    },
    //获取URL中的值
    getURLQuery(query) {
      const search = new URLSearchParams(location.search);
      const value = search.get(query);
      return value;
    },

    //加载方法
    async loadFunction() {
      this.deleteURLToken();

      if (this.userGuid) {
        this.$store.commit("setUserId", this.userGuid)
      }
      if (this.hideNav) return this.loading = false;
      this.storeData();
    },

    //加载商店信息
    async storeData() {
      const storeBooks = await this.$api.bookManage.storeBookFind({ storeId: STORE_ID }).then((res) => res.data || []);
      this.storeBooks = storeBooks;
      this.$store.commit("setStoreBooks", storeBooks);

      const currentStore = await this.$api.bookManage.storeFindOne().then((res) => res.data || []);
      this.$store.commit("setCurrentStore", currentStore);

      if (!this.userGuid) return this.afterLoad();

      this.getUserInfo();
    },
    //获取用户信息
    async getUserInfo() {
      const userGuid = this.userGuid;
      const user = await this.$api.unifydata.getStudent({ userGuid }).then((res) => res.data);
      if (user) {
        user.NickName = user.NickName || user.Name;
        user.token = this.token;
        user.HeadPhotoPath = headPath(user.HeadPhotoPath);
      }
      this.user = user;
      this.$store.commit("userInfo", user);

      this.loadUser();
    },

    //加载用户相关信息
    async loadUser() {
      const user = this.user;
      const userGuid = this.userGuid;
      const bookUser = await this.$api.bookManage.bookUserNew({
        fk_user_id: userGuid,
        account: user.Name,
        name: user.NickName,
        avatar: user.HeadPhotoPath,
      }).then((res) => res.data || null);

      //获取我的购买订单
      const orderList = await this.$api.payorder.getOrderList(userGuid).then((res) => res.data || []);
      this.storeBooks.forEach(book => {
        Vibrant.from(book.extension_info.cover).getPalette().then((palette) => {
          let primaryColor = palette.DarkVibrant.hex;
          book.primaryColor = `${primaryColor}ba`;
        })

        const order = orderList.find((oitem) => oitem.system_id === book.system_id);
        if (order) book.isPaid = order.isPaid;

        if (bookUser?.join_class) {
          const { join_class } = bookUser;
          const joinClass = join_class.find((item) => item.fk_publishing_id === book.fk_publishing_id);
          if (joinClass) {
            book.fk_book_class_id = joinClass.fk_book_class_id;
          }
        }
      })
      this.$store.commit("setStoreBooks", this.storeBooks);

      this.loading = false;
      this.afterLoad();
    },

    async afterLoad() {
      //获取栏目
      const columnList = await this.$api.digitalBook.getDigitalBookColumn().then((res) => res.data || []);
      this.$store.commit("setColumnList", columnList);
      if (!this.userGuid) return this.loading = false;

      //获取vip身份
      const studentVip = await this.$api.payorder.getStudentVip(this.userGuid).then((res) => res.data || null);
      if (studentVip) {
        const vips = studentVip.content ? JSON.parse(studentVip.content) : [];
        const vip = vips.find((item) => item.type == VIP_PLATFORM);
        if (vip) {
          vip.isPastdue = !vip.deadline || new Date() < new Date(item.deadline) ? false : true;
          vip.deadline = vip.deadline ? moment(vip.deadline).format('YYYY-MM-DD') : null;
          this.$store.commit("studentVip", vip);
        }
      }

    },
  }
};
</script>

<style></style>
