<template>
  <div class="nav-box" :style="{ color: column?.config.textColor }" :class="{ desktop: $store.state.isElectron }">
    <div class="left-box">
      <div class="logo-box" @click="returnHome">
        <svg class="svg-icon return-icon" @click.stop="returnLast" v-if="showReturn && !phoneMode">
          <use xlink:href="#icon-last" />
        </svg>
        <img class="logo-icon" src="https://img.lessonplan.cn/IMG/yuanxi/images/nav/nav-logo.png" alt v-if="!column" />
        <img class="logo-icon" :src="column.config?.logo" v-else />
      </div>

      <div class="menu-box" v-if="!phoneMode">
        <div class="menu-item" :class="{ selected: item.selected }" v-for="item of menuList" v-show="item.show"
          :key="item.name" @click="chooseMenu(item)">{{ item.name }}</div>
        <div class="menu-item" @click="routerAbout()" v-if="isShowAbout">关于我们</div>
      </div>
    </div>


    <div class="user-box" v-if="!phoneMode">
      <div class="user-handler" v-if="$store.state.isElectron && $store.state.electronConfig.online"
        @click="routerDownload()">
        <svg class="icon-svg">
          <use xlink:href="#icon-xiazai1"></use>
        </svg>
      </div>
      <div class="user-handler">
        <a-tooltip title="帮助">
          <svg class="icon-svg" @click="showHelp = true">
            <use xlink:href="#icon-help"></use>
          </svg>
        </a-tooltip>
        <a-tooltip title="桌面端">
          <svg class="icon-svg" @click="downApp()">
            <use xlink:href="#icon-application"></use>
          </svg>
        </a-tooltip>
        <a-tooltip title="手机端">
          <svg class="icon-svg" @click="showGuide = true">
            <use xlink:href="#icon-phone"></use>
          </svg>
        </a-tooltip>
      </div>

      <a-button class="login-button" @click="loginIn" v-if="!userInfo">登录</a-button>
      <div class="logined" v-if="userInfo">
        <a-dropdown :trigger="['click']">
          <i class="avatar" :style="{ backgroundImage: 'url(' + userInfo?.HeadPhotoPath + ')' }"></i>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="routerPath(`/personal`)">
                <span>个人中心</span>
              </a-menu-item>
              <a-menu-item @click="logout()">
                <span>退出登录</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="desktop-function" v-if="$store.state.isElectron">
        <svg @click="ipcRenderer.send('window:minimize')">
          <use xlink:href="#icon-zuixiaohua" />
        </svg>
        <svg @click="isMaxSize = !isMaxSize; ipcRenderer.send('window:maximize');">
          <use xlink:href="#icon-zuidahua" v-if="isMaxSize" />
          <use xlink:href="#icon-zuidahua1" v-if="!isMaxSize" />
        </svg>
        <svg class="icon-guanbi" @click="deskTopClose()">
          <use xlink:href="#icon-close-line" />
        </svg>
      </div>

    </div>

    <phoneGuide @closeBox="showGuide = false" v-if="showGuide"></phoneGuide>
    <help v-if="showHelp" @close="showHelp = false"></help>
  </div>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, LESSONPLAY_TOKEN, ONLINEURL } from '@/config/url';
import { mapState } from 'vuex';
import phoneGuide from './phone-guide/phone-guide.vue';
import help from './help.vue';

export default {
  name: "navroot",
  components: {
    phoneGuide,
    help
  },
  data() {
    return {
      menuList: [
        { name: "首页", url: "/home", selected: false, show: false },
        { name: "我的书架", url: "/mycourse", selected: false, show: false }
      ],
      showGuide: false,
      isShowAbout: false,
      showHelp: false,

      isMaxSize: true,
      ipcRenderer: null,

      desktopUrl: 'https://electronserve.lessonplan.cn/yuanxi/win64/元习 Setup 1.0.1.exe',
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo || null,
      column: (state) => state.column || null,
      showReturn: (state) => state.showReturn || false,
      phoneMode: (state) => state.phoneMode || false,
    })
  },
  watch: {
    userInfo: {
      handler(newValue) {
        if (newValue) {
          this.menuList.forEach(menu => {
            menu.show = true;
          })
        }
      },
      immediate: true
    },
  },
  async mounted() {
    this.judgeMenu();
    this.routerChange();
    this.judgeFirstOpen();

    let desktopUrl = await this.$api.download.getDesktopUrl();
    if (desktopUrl) this.desktopUrl = desktopUrl;

    if (this.$store.state.isElectron) {
      const { ipcRenderer } = window.require("electron");
      this.ipcRenderer = ipcRenderer;
      ipcRenderer.send("config:read");
      ipcRenderer.on("setDownloadSrc", (event, data) => {
        this.downloadSrc = data;
      });
      ipcRenderer.once("config:read", (event, data) => {
        this.downloadSrc = data.downloadSrc;
        this.online = data.online
      });
    }

    const { name } = this.$route;
    if (name == 'column') {
      this.isShowAbout = true;
    }
  },
  methods: {
    //监听路由变化
    routerChange() {
      this.$router.afterEach((to, from) => {
        if (this.$route.name == 'column') this.isShowAbout = true;
        else this.isShowAbout = false;
        this.judgeMenu();
      })
    },
    setDownloadSrc() {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("dialog:open", "setDownloadSrc");
    },
    //判断当前菜单
    judgeMenu() {
      this.$nextTick(() => {
        this.menuList.forEach(menu => {
          if (menu.url != '/' && location.href.indexOf(menu.url) > -1) menu.selected = true;
          else menu.selected = false;
        })
      })
    },
    //选择菜单
    chooseMenu(item) {
      this.menuList.forEach(menu => { menu.selected = false });
      item.selected = true;
      this.resetScroll();

      this.$router.push(item.url);
    },
    //退出
    logout() {
      this.$nzconfirm('确定要退出登录吗?', () => {
        let token = window.localStorage.getItem(LESSONPLAY_TOKEN);
        window.localStorage.removeItem(LESSONPLAY_TOKEN);
        if (this.$store.state.isElectron) {
          this.$router.push('/login');
        } else {
          location.href = `${PASSPORT_CLIENT_SERVER}/auth/logout?token=${token}&redirectURI=${ONLINEURL}&isTourist=true`;
        }
      })
    },
    //跳转至个人中心
    routerPath(url) {
      this.$router.push(url);
      this.menuList.forEach(menu => { menu.selected = false })
      this.resetScroll();
    },
    returnHome() {
      this.$router.push('/home');
      this.resetScroll();
    },
    //返回
    returnLast() {
      const { query } = this.$route;
      if (query?.from) {
        this.$router.push(`/${query.from}`);
      }
      else if (this.column) {
        this.$router.push(`/column/${this.column.pk_digital_column_id}`);
      }
      else {
        this.$router.push('/home');
      }

      this.resetScroll();
    },
    //重置滚动条
    resetScroll() {
      this.$store.commit('showReturn', false);
      $('#content').animate({ scrollTop: 0 }, 300);
    },
    //关于我们
    routerAbout() {
      if (this.column)
        this.$store.commit("routerAbout", true);
    },
    //跳转登录
    loginIn() {
      if (this.$store.state.isElectron) {
        this.$router.push('/login');
      } else {
        let herf = location.href;
        if (herf.indexOf('?') > -1) { herf = herf.split('?')[0] };
        location.href = `${PASSPORT_CLIENT_SERVER}/yuanxi?redirectURI=${herf}`;
      }
    },
    //下载桌面应用
    downApp() {
      this.$Modal.confirm({
        okText: '确定',
        cancelText: '取消',
        title: `确定要下载桌面应用吗`,
        content: '下载后，请在浏览器下载中心，查看下载进度',
        onOk: () => {
          this.openUrl(this.desktopUrl);
        }
      })
    },
    //打开新网页
    openUrl(url) {
      window.location.href = url;
    },
    //跳转我的下载
    routerDownload() {
      this.$router.push("/download");
    },
    //桌面退出
    deskTopClose() {
      this.$nzconfirm('确定要关闭窗口吗?', () => {
        this.ipcRenderer.send('window:close')
      })
    },

    //判断用户是否首次打开帮助
    async judgeFirstOpen() {
      if (!this.userInfo || this.phoneMode) return;

      const { PK_UserGuid } = this.userInfo;
      const bookuser = await this.$api.bookManage.bookUserFindOne(PK_UserGuid).then(res => res.data);
      if (!bookuser) return;

      const { pk_book_user_id, record } = bookuser;
      if (!record || !record.first_open_help) {
        this.showHelp = true;
        let newRecord = {};
        if (record) newRecord = { ...record, first_open_help: true };
        else newRecord = { first_open_help: true };

        this.$api.bookManage.bookUserUpdate({
          pk_book_user_id,
          record: newRecord
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "./nav.scss";
</style>