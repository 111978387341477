<template>
  <div class="home-box fadeIn">
    <template v-if="normalRender">
      <div class="recommended-box">
        <div class="recommended" ref="recommended" @mouseenter="rdMouse('enter')" @mouseleave="rdMouse('leave')">
          <div class="rd-item rd-pre" :class="{ hide: rdList.length < 4 }"
            :style="{ backgroundImage: 'url(' + rdLast.extension_info?.cover + '!260x390)' }" v-if="rdLast"
            @click="rdHolder(rdList.length - 1)">
          </div>
          <div class="rd-item" :ref="'rd_' + i" :class="{ actived: rdActive == i && !phoneMode }"
            :style="{ backgroundImage: 'url(' + rd.extension_info?.cover + '!260x390)', backgroundColor: (rdActive == i && !phoneMode) ? rd.primaryColor : 'transparent' }"
            v-for="(rd, i) of rdList" @click="rdChoose(i, '')">
            <div class="rd-info" v-if="rdActive == i && !phoneMode">
              <div class="rd-price">
                <span v-if="rd.isPaid || rd.hasJoin">已购买</span>
                <span v-else-if="rd.is_unique_book">内测版本</span>
                <span v-else-if="rd.price === 0">免费</span>
                <span v-else>¥{{ rd.price }}</span>
              </div>
              <div class="rd-title ellipsis">{{ rd.name }}</div>
              <div class="rd-subTitle ellipsis" v-if="rd.chief_editor_name">{{ rd.chief_editor_name }}</div>
            </div>
          </div>

          <template v-if="rdList.length > 4">
            <div class="rd-item rd-last" :style="{ backgroundImage: 'url(' + rd.extension_info?.cover + '!260x390)' }"
              v-for="(rd, i) of rdList" @click="rdHolder(i)">
            </div>
          </template>
        </div>
        <div class="recommended-dot" v-if="!phoneMode">
          <div class="dot-box" :class="{ actived: rdActive == i }" v-for="(rd, i) of rdList"
            @click="rdChoose(i, 'dot')">
            <i class="dot"></i>
          </div>
        </div>
      </div>
      <div class="title-box">
        <div class="main-title">元习</div>
        <div class="sub-title">学习如何学习</div>
        <a-input-search class="input-search" v-model:value="keyword" placeholder="请输入教材名称搜索" @search="onSearch" />
      </div>
      <div class="course">
        <div class="catalog-box" v-if="catalogList.length > 1">
          <div class="catalog">
            <div class="catalog-item" :class="{ selected: catActive == i, noicon: !catalog.icon }"
              v-for="(catalog, i) of catalogList" @click="chooseCatalog(i)">
              <i class="catalog-icon" :style="{ backgroundImage: 'url(' + catalog.icon + ')' }" v-if="catalog.icon"></i>
              <span class="catalog-title">{{ catalog.name }}</span>
            </div>
          </div>
          <div class="catalog-page catalog-left" @click="catalogScroll('left')">
            <svg class="svg-icon">
              <use xlink:href="#icon-last" />
            </svg>
          </div>
          <div class="catalog-page catalog-right" @click="catalogScroll('right')">
            <svg class="svg-icon">
              <use xlink:href="#icon-next" />
            </svg>
          </div>
        </div>

        <div class="course-box">
          <div class="course-item-box" :class="{ hide: i > 9 }" v-for="(book, i) of showList"
            @click="routerCourse(book, 'open')">
            <div class="course-item" :style="{ backgroundImage: 'url(' + book.extension_info?.cover + '!260x390)' }">
              <div class="course-top" @click.stop>
                <a-tooltip title="详情">
                  <div class="svg-box info" @click="routerPath(`/course-info/${book.pk_store_book_id}`)">
                    <svg class="icon-svg">
                      <use xlink:href="#icon-info"></use>
                    </svg>
                  </div>
                </a-tooltip>
              </div>
            </div>
            <div class="course-name">{{ book.name }}</div>
          </div>
          <emptyBox warning="Sorry,暂无此类教材" v-if="showList.length == 0"></emptyBox>
        </div>

        <div class="course-bottom">
          <div class="pagination pagination-left" @click="changePage(page - 1)">
            <svg class="svg-icon">
              <use xlink:href="#icon-last" />
            </svg>
          </div>
          <div class="pagination pagination-center" @click="routerPath('/all-course')"
            v-if="catalogList[catActive]?.id == ''">
            全部
          </div>

          <div class="pagination pagination-right" @click="changePage(page + 1)">
            <svg class="svg-icon">
              <use xlink:href="#icon-next" />
            </svg>
          </div>
        </div>
      </div>

      <column-list v-if="!phoneMode"></column-list>

      <!-- <div class="card-box">
        <img class="card-title" :src="cardTitle" alt />
        <div class="card-subtitle">把传统教材阅读，变成探索、游戏与社交</div>
        <div class="card">
          <div class="card-list" ref="card">
            <div class="card-item" @click="holderCard(card.num)" v-for="card of cardHolder">
              <i class="card-cover i-icon" :style="{ backgroundImage: 'url(' + card.image + ')' }"></i>
              <div class="card-content">{{ card.content }}</div>
            </div>
            <div class="card-item" :ref="'card_' + i" :class="{ actived: cardActive == i && !phoneMode }"
              v-for="(card, i) of card_list" @click="cardChoose(i)">
              <i class="card-cover i-icon" :style="{ backgroundImage: 'url(' + card.image + ')' }"></i>
              <div class="card-content">{{ card.content }}</div>
            </div>

            <div class="card-item" v-for="(card, i) of card_list" @click="holderCard(i)">
              <i class="card-cover i-icon" :style="{ backgroundImage: 'url(' + card.image + ')' }"></i>
              <div class="card-content">{{ card.content }}</div>
            </div>
          </div>
        </div>
        <div class="card-dot" v-if="!phoneMode">
          <i class="dot" :class="{ actived: cardActive == i }" v-for="(card, i) of card_list" @click="cardChoose(i)"></i>
        </div>
      </div> -->
      <foot v-if="!phoneMode"></foot>
    </template>
  </div>
</template>

<script>
import foot from "@/component/public/foot/foot.vue";
import router from "@/router";
import navroot from "@/component/public/nav/nav.vue";
import emptyBox from "@/component/public/empty-box/empty-box.vue";
import columnList from "@/component/column/column-list.vue";
import { mapState } from "vuex";

export default {
  name: "home",
  components: {
    navroot,
    foot,
    emptyBox,
    columnList
  },
  data() {
    return {
      catalogList: [],
      catActive: 0,

      allStoreBooks: [],
      storeBooks: [],
      showList: [],

      page: 1,
      pageSize: 10,
      maxPage: 1,
      needScroll: false,
      listenerWindow: true,

      rdList: [],
      rdLast: null,
      rdSlideshow: 3000,
      rdActive: 0,
      rdInterval: null,

      card_list: [
        {
          content:
            "学生学习时，除了能读图文、听声音、看视频、看动画、多角度的三维观察，让教材内容变得生动、形象、直观，还可以通过投票、讨论、选择、判断、词云、图片瀑布流、排序、匹配、结构化反思等多种方式进行强互动、强练习，让教法真正融入教材，大大提高了学习的深度和效果。",
        },
        {
          content:
            "读者进入教材仿佛置身学习现场，可以自由地分享和交流，通过情感与社交加深学习效果。他们可以打造自己个性化的数字身份，发布丰富的表情包，可以和其它同学一起，进行圆桌共创，组队挑战难题，交换学习困惑与心得，还可以预约教材作者、任课教师或外部导师加入进来交流指导。",
        },
        {
          content:
            "我们提供了游戏化的机制，让学生扮演角色，在书本中通关挑战，完成一个个的任务和训练，还能获得积分和徽章、不断成长等级，极大地提高了学习热情。",
        },
        {
          content:
            "你可以非常便利地把教材内容变成一次完整的比赛，教师在教材后端可以快速发布比赛通知、报名审核、提交作品、邀请评委、设立奖项、排名颁奖，还可以延伸到现场的路演、答辩，让学生积极投入其中，真正实现以赛促学、以赛促训。",
        },
        {
          content:
            "我们设计了强大的编辑后台，让你随时设计新的教材内容，图文、视频、动画、练习、互动、游戏，都可以方便地更新，并实时发送到学生端，同步取消已经发布的旧内容，保证教材跟上学科和行业的前沿发展，不再滞后，不再掉队。",
        },
        {
          content:
            "你将可以从多个维度，可视化地了解学生的阅读情况统计和学习轨迹，针对进度不佳的学生，系统会自动预警，帮助你真正做到个性化精准干预，学生阅读时的不懂，也会实时反馈，为教材的不断迭代改进提供决策依据。",
        },
      ],

      cardHolder: [],
      cardActive: 0,
      cardTransform: 130,
      cardTitle: "https://img.lessonplan.cn/IMG/yuanxi/images/home/card-title.png",
      cardInterval: null,

      pageTemplate: null,
      navContent: null,
      newOpenBook: null,
      keyword: "",

      loadding: true,
      updateDgUser: false,
      normalRender: true,
    };
  },
  computed: {
    ...mapState({
      columnPublishs: (state) => state.columnList.filter((item) => item.type == "publish") || [],
      columnEducations: (state) => state.columnList.filter((item) => item.type == "education") || [],
      vipPastdue: (state) => state.studentVip?.isPastdue || false,
      userInfo: (state) => state.userInfo || null,
      phoneMode: (state) => state.phoneMode || false,
    })
  },
  mounted() {
    const { isElectron } = this.$store.state;

    this.judgeCardMove();
    this.setStoreBook();

    if (isElectron) {
      const electronConfig = this.$store.state.electronConfig;
      if (!electronConfig.online) {
        // 无网
        if (!electronConfig.token) {
          this.$message.error({
            content: "请先联网(联网登录一次后可离线使用)",
            duration: 0,
            top: 200
          });
        } else {
          this.userGuid = electronConfig.userGuid;
          this.token = electronConfig.token;
          this.$router.replace("/download");
        }
        this.normalRender = false;
        return;
      }
    }
  },
  methods: {
    //设置pageTemplate
    setStoreBook() {
      this.setCatalogList();
      const { storeBooks } = this.$store.state;
      this.allStoreBooks = JSON.parse(JSON.stringify(storeBooks));

      const rdList = this.allStoreBooks.filter((item) => item.is_recommend == 1);
      this.rdLast = rdList[rdList.length - 1];
      this.rdList = rdList;

      const card_list = this.card_list;
      card_list.forEach((item, i) => {
        item.num = i;
        item.image = `https://img.lessonplan.cn/IMG/yuanxi/images/home/card/${i + 1}.png`;
      });
      this.cardHolder = card_list.slice(-2);
      this.card_list = card_list;

      //监听窗口变化
      this.isPhoneMode();
      window.addEventListener("resize", this.isPhoneMode);
    },
    setCatalogList() {
      const { currentStore } = this.$store.state;
      const typeGroups = currentStore.typeGroups || [];

      let catalogList = [{ name: "全部", id: "" }]
      if (typeGroups.length > 0) catalogList = [...catalogList, ...typeGroups[0].list];
      this.catalogList = catalogList;

      let catActive = 0;
      let { type } = this.$route.params; // 获取当前路由参数
      catalogList.forEach((item, i) => {
        if (type && type == item.type) catActive = i;
      });
      this.catActive = catActive;
    },


    //是否是手机端
    isPhoneMode() {
      if (!this.listenerWindow) return;
      this.listenerWindow = false;
      setTimeout(() => { this.listenerWindow = true }, 3000);

      //去除轮训
      if (this.rdInterval) clearInterval(this.rdInterval);
      //自动轮播
      setTimeout(() => {
        if (!this.phoneMode && this.rdList.length > 1) this.rdAutoMove();
      }, 100);

      if (this.phoneMode) this.pageSize = 6;
      else this.pageSize = 10;

      this.chooseCatalog(this.catActive);

      this.cardScroll();
      this.listenCard();
    },

    ////////////////////////////////推荐轮播
    //选择推荐
    rdChoose(i, type) {
      let rdActive = this.rdActive;
      if ((i == rdActive && type != "dot") || this.phoneMode)
        return this.routerCourse(this.rdList[i], "open");

      let dom = this.$refs.recommended;
      if (!dom) return;
      dom.style.transitionDuration = `1s`;

      let item_dom = this.$refs[`rd_${i}`];
      if (item_dom > 0) item_dom[0].style.transition = `all .7s ease`;

      let old_item_dom = this.$refs[`rd_${rdActive}`];
      if (old_item_dom > 0) old_item_dom[0].style.transition = `all .7s ease`;

      //恢复
      setTimeout(() => {
        dom.style.transitionDuration = `.3s`;
        if (item_dom.length > 0) item_dom[0].style.transition = `all .3s ease`;
        if (old_item_dom > 0) old_item_dom[0].style.transition = `all .3s ease`;
      }, 1000);

      this.rdMove(i);
    },
    //选择占位
    rdHolder(i) {
      let rdActive = this.rdActive;
      if (i == rdActive) return;

      this.rdMove(i);
    },
    //rd鼠标移入移出
    rdMouse(type) {
      if (this.phoneMode) return;
      if (type == "enter") {
        window.addEventListener("mousewheel", this.rdMousewheel);
        $("body").css("overflow-y", "hidden");
        $("body").css("padding-right", "10px");
      } else {
        window.removeEventListener("mousewheel", this.rdMousewheel);
        $("body").css("overflow-y", "auto");
        $("body").css("padding-right", "0px");
      }
    },
    //监听鼠标滚轮
    rdMousewheel(e) {
      let direction = e.deltaY > 0 ? "down" : "up";
      let rdActive = this.rdActive;
      let rdList = this.rdList;

      if (direction == "down") {
        if (rdActive == rdList.length - 1) this.rdMove(0);
        else this.rdMove(rdActive + 1);
      } else if (direction == "up") {
        if (rdActive == 0) this.rdMove(rdList.length - 1);
        else this.rdMove(rdActive - 1);
      }
    },
    //轮播图移动
    rdMove(i) {
      if (this.rdInterval) clearInterval(this.rdInterval);

      this.rdActive = i;
      let dom = this.$refs.recommended;
      if (!dom) return;
      let left_ts = i ? i * 280 + 350 : 350;
      dom.style.transform = `translate3d(-${left_ts}px, 0px, 0px)`;

      this.rdAutoMove();
    },
    //轮播图自动滚动
    rdAutoMove() {
      this.$nextTick(() => {
        let rdActive = this.rdActive;
        let rdList = this.rdList;

        if (this.rdInterval) clearInterval(this.rdInterval);
        this.rdInterval = setInterval(() => {
          if (rdActive == rdList.length - 1) this.rdChoose(0, "");
          else this.rdChoose(rdActive + 1, "");
        }, this.rdSlideshow);
      })
    },
    ////////////////////////////////分类
    //选择分类
    chooseCatalog(i) {
      this.catActive = i;
      const catalog = this.catalogList[i];
      let storeBooks = this.allStoreBooks.filter((item) => item.name.indexOf(this.keyword) > -1)

      if (catalog.id) {
        storeBooks = storeBooks.filter((item) => item.types.indexOf(catalog.id) > -1);
      }
      this.storeBooks = storeBooks;

      this.maxPage = Math.ceil(this.storeBooks.length / this.pageSize) || 1;
      this.needScroll = false;
      this.changePage(1);

      this.loadding = false;
    },
    //翻页
    changePage(page) {
      if (page <= 0) return this.$message.warning("已经是第一页了");
      else if (page > this.maxPage) return this.$message.warning("已经是最后一页了");
      this.page = page;

      let pageSize = this.pageSize;
      let storeBooks = JSON.parse(JSON.stringify(this.storeBooks));
      this.showList = storeBooks.slice((page - 1) * pageSize, page * pageSize);
      this.page = page;

      if (this.needScroll) {
        if (this.phoneMode) {
          $("#content").animate({ scrollTop: 450 }, 300);
        } else {
          $("#content").animate({ scrollTop: 870 }, 300);
        }
      }
      this.needScroll = true;
    },
    //滚动条移动
    catalogScroll(type) {
      let scroll = $(".catalog").scrollLeft();
      if (type == "left")
        $(".catalog").animate({ scrollLeft: scroll - 700 }, 300);
      else if (type == "right")
        $(".catalog").animate({ scrollLeft: scroll + 700 }, 300);
    },
    //跳转
    routerCourse(item, type) {
      if (type == "open" && (item.isPaid || (item.hasJoin && !this.vipPastdue))) {
        this.study(item);
      }
      else {
        $("#content").css("overflow-y", "auto");
        $("#content").css("padding-right", "0px");
        router.push(`/course-info/${item.pk_store_book_id}`);
      }
    },
    //学习
    study(item) {
      const { pk_store_book_id, system_id, single_url } = item;
      let url = `/bookReader/${pk_store_book_id}`;
      if (single_url) {
        url = `${single_url}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`;
      }
      window.open(url);
    },

    //关键词搜索
    onSearch() {
      this.routerPath(`/all-course?keyword=${this.keyword}`);
    },
    //跳转
    routerPath(url) {
      this.$router.push(url);
    },
    //////////////////////////////卡片轮播
    //判断初始card位移
    judgeCardMove() {
      this.cardTransform = 1150 - window.innerWidth / 2;

      setTimeout(() => {
        this.cardMove(0);
      }, 1000);

      let that = this;
      $(window).resize(function () {
        that.cardTransform = 1150 - window.innerWidth / 2;
        that.cardMove(that.cardActive);
      });
    },
    //选择卡片
    cardChoose(i) {
      if (this.phoneMode) return;

      let cardActive = this.cardActive;
      if (i == cardActive) return;

      let dom = this.$refs.card;
      if (!dom) return;
      dom.style.transitionDuration = `.3s`;
      //恢复
      setTimeout(() => {
        dom.style.transitionDuration = `0s`;
      }, 400);

      this.cardMove(i);
    },
    //选择占位
    holderCard(i) {
      let cardActive = this.cardActive;
      if (i == cardActive) return;

      this.cardMove(i);
    },
    //轮播图移动
    cardMove(i) {
      this.cardActive = i;
      let dom = this.$refs.card;
      let ctf = this.cardTransform;
      if (!dom) return;

      let left_ts = 0 - (i * 440 + ctf + 20);
      dom.style.transform = `translate3d(${left_ts}px, 0px, 0px)`;
    },

    //监听鼠标引入移出片
    listenCard() {
      this.$nextTick(() => {
        const cardItem = this.$el.querySelectorAll('.card-item');
        if (!cardItem) return;
        cardItem.forEach((item) => {
          item.onmouseenter = () => {
            clearInterval(this.cardInterval);
          };
          item.onmouseleave = () => {
            this.cardScroll();
          };
        })
      })
    },
    //卡片轮播
    cardScroll() {
      this.$nextTick(() => {
        if (this.cardInterval) clearInterval(this.cardInterval);

        this.cardInterval = setInterval(() => {
          if (this.cardActive == this.card_list.length - 1) this.cardChoose(0);
          else this.cardChoose(this.cardActive + 1);
        }, this.rdSlideshow);
      })
    },
  },
  beforeDestroy() {
    //去除轮训
    if (this.rdInterval) clearInterval(this.rdInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
</style>
