import { PASSPORT_CLIENT, CLIENT_ACCOUNT } from '../baseURL'
import axios from '../config'

export default {
  //登录授权
  auth(payload) {
    const { username, password } = payload
    return axios.get(`${PASSPORT_CLIENT}/auth?username=${username}&password=${password}`, payload)
  },
  //验证token有效性
  verifyToken(token) {
    return axios.get(`${PASSPORT_CLIENT}/auth/verify`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
  },
  //注册
  register(Phone, Password) {
    return axios.post(`${CLIENT_ACCOUNT}/user/register`, {
      Name: Phone, NickName: Phone, Password,
    })
  },
  //检查是否在小程序中
  checkInMiniProgram() {
    return new Promise(resolve => {
      if (-1 === navigator.userAgent.toLowerCase().indexOf('micromessenger')) {
        resolve(false)
      }
      else {
        // eslint-disable-next-line
        wx.miniProgram.getEnv(res => {
          if (res.miniprogram) resolve(true)
          else resolve(false)
        })
      }
    })
  }
}