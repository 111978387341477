<template>
  <div class="popup-box">
    <div class="blackscreen"></div>
    <div class="box-content">
      <img class="cover" :src="cover" />
      <div v-if="showAchievement">
        <div class="name-box" v-if="type == 'remind'">
          <span>恭喜你获得</span>
          <span class="showAchievement-name">{{ showAchievement?.name }}</span>
          <span>徽章</span>
        </div>
        <div class="content-box">“ {{ showAchievement?.content }} ”</div>
        <div class="describe-box">（{{ showAchievement?.describe }}）</div>
      </div>
      <a-button class="achievement-button" size="large" type="primary" @click="close">我知道了</a-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    type: String,
    showAchievement: {
      default: null
    },
  },
  name: "show_achievement",
  data() {
    return {
      cover: ""
    }
  },
  async mounted() {
    let showAchievement = this.showAchievement;
    if (!showAchievement) return this.cover = `https://img.lessonplan.cn/IMG/LessonPlay/public/upgrade.png`;
    if (showAchievement.cover) this.cover = showAchievement.cover;
    else this.cover = this.defaultCover;
  },
  updated() {
    this.showCover();
  },
  methods: {
    //显示封面
    showCover() {
      let showAchievement = this.showAchievement;
      if (!showAchievement) return this.cover = `https://img.lessonplan.cn/IMG/LessonPlay/public/upgrade.png`;
      if (showAchievement.cover) this.cover = showAchievement.cover;
      else this.cover = this.defaultCover;
    },
    //关闭
    close() {
      this.$emit("closeBox", true);
    }

  }
}
</script>

<style scoped lang="scss">
@import "./show_achievement.scss";
</style>